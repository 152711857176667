import { version } from './version';
import { ApiEnvironment } from '@clients/api';
import {
  VersionEnvironment,
  SentryEnvironment,
  FirebaseEnvironment,
} from '@clients/helper';

export const environment: VersionEnvironment &
  ApiEnvironment &
  SentryEnvironment &
  FirebaseEnvironment & { production: boolean } = {
  production: true,
  version,
  api: 'https://api.dev.get-the-guest.com/get-the-guest-api',
  sentry: {
    dns: 'https://826ecc3191be4f9a852c3c551684d0f1@sentry.fortysix.world/13',
    environment: 'dev',
    enabled: true,
  },
  firebase: {
    apiKey: 'AIzaSyDAM7pGX7LOhg_ySbGVo4NkE-TdLsMZjdw',
    authDomain: 'get-the-guest-dev.firebaseapp.com',
    databaseURL: 'https://get-the-guest-dev.firebaseio.com',
    projectId: 'get-the-guest-dev',
    storageBucket: 'get-the-guest-dev.appspot.com',
    messagingSenderId: '676528110825',
    appId: '1:676528110825:web:40b09198bc0a30777c4478',
    measurementId: 'G-MDHSNXS5XM',
  },
};
